<template>
  <div class="summit-background">
		<div class="mask-pivot-left">
			<div class="image-container-left">
				<img class="left-image" />
			</div>
		</div>

		<div class="mask-pivot-right">
			<div class="image-container-right">
				<img class="right-image" />
			</div>
		</div>
  </div>
</template>

<script>
export default {
  name: 'summit_background',
};
</script>

<style scoped>
.summit-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
	justify-content: center;
	align-items: center;

  --animation-duration: 1000s;
  --child-animation-duration: calc(var(--animation-duration)/2);

	background-color: #B56372;
}

.mask-pivot-left {
	position: fixed;
	right: calc(50vw + 50px);
}

.image-container-left {
  -webkit-mask-image: url('./assets/mask.png'); /* Masking image */
  mask-image: url('./assets/mask.png'); /* Masking image */
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: repeat;
  mask-repeat: repeat;

	
	width: calc(550px * 2.25);
	height: calc(550px * 2.25);

	display: flex;
	justify-content: center;
	align-items: center;

	animation: rotation var(--animation-duration) linear infinite;
	animation-direction: reverse;
}

.left-image {
  content: url('./assets/bg-books.jpeg');
	repeat: true;
	height: 100%;

	animation: rotation var(--child-animation-duration) linear infinite;
}






.mask-pivot-right {
	position: fixed;
	left: calc(50vw + 50px);
}

.image-container-right {
  -webkit-mask-image: url('./assets/mask.png'); /* Masking image */
  mask-image: url('./assets/mask.png'); /* Masking image */
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: repeat;
  mask-repeat: repeat;

	
	width: calc(550px * 2.25);
	height: calc(550px * 2.25);

	display: flex;
	justify-content: center;
	align-items: center;

	animation: rotation var(--animation-duration) linear infinite;
}

.right-image {
  content: url('./assets/bg-books.jpeg');
	repeat: true;
	height: 100%;
	
	animation: rotation var(--child-animation-duration) linear infinite;
	animation-direction: reverse;
}

@keyframes rotation {
		from {
				transform: rotate(0deg);
		}
		to {
				transform: rotate(360deg);
		}
}
</style>
