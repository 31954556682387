<template>
  <div class="summit-banner">
    <img class="banner-img" src="./assets/logo.png" />
  </div>
</template>

<script>
export default {
  name: 'summit-banner',
};
</script>

<style scoped>
.summit-banner {
  background-color: #c3d7e7FF;
  width: 100%; /* Span across the full width of the page */
  padding: 20px 0;
  position: fixed; /* Fix the banner in the middle of the viewport */
  top: 50%; /* Position it vertically in the middle */
  left: 0;
  transform: translateY(-50%); /* Adjust the position to be exactly in the middle */
  text-align: center; /* Center the text inside the banner */
  font-size: 18px; /* Optional: adjust the text size */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow */
}

.banner-img {
	position: relative;
	width: 100%;
	max-width: 664px;
}
</style>
