<template>
  <div id="app">
		<summit_background />
		<summit_banner />
  </div>
</template>

<script>
import summit_banner from './components/Banner.vue';
import summit_background from './components/Background.vue';

export default {
	name: 'App',
	components: {
		summit_banner,
		summit_background
	},
	
	setup() {
  },

  mounted() {
    document.title = "Better than Fiction";
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  margin-top: 60px;
}
</style>
